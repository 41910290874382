"use client";

import React, { useCallback } from "react";
import RouterLink from "next/link";
import { useRouter, useSearchParams } from "next/navigation";

import { Link, LinkVariant, Panel } from "@jasperlabs/jux-next";
import TenantName from "tenants/components/TenantName";

import { CARUSO_MARKETING_SITE } from "constants/external-links";
import { useCaptureGraphQLError } from "../../../api";
import { LoginForm } from "../../../components/organisms/authentication";

import { toast } from "../../../components/utilities/toast";
import { useSession } from "../../../contexts/session";
import { useRouterState } from "../../../contexts/CustomRouter/RouterState";
import LoginHeader from "./LoginHeader";

const Login = () => {
	const { routerState } = useRouterState();
	const router = useRouter();
	const searchParams = useSearchParams();
	const returnUrl = searchParams.get("returnUrl");

	const { pageHeading, pageBlurb } = routerState || {};
	const headingText = pageHeading || "Welcome back";
	const blurbText = pageBlurb || "";
	const { hasAuthenticatedSession, hasSessionExpired } = useSession();
	const captureLoginError = useCaptureGraphQLError();

	const handleLoginAttemptFailed = useCallback(() => {
		toast.warning("The email address or password provided is incorrect.");
	}, []);

	const handleLoginError = useCallback(
		(error: any) => {
			captureLoginError(error);
			toast.errorGeneric();
		},
		[captureLoginError],
	);

	if (hasAuthenticatedSession && !hasSessionExpired) {
		router.push(returnUrl ?? "/");
	}

	return (
		<section className="bg-app-header h-full min-h-screen pt-6 md:pt-10">
			<div className="max-w-container-lg mx-auto w-full justify-center px-3">
				<LoginHeader heading={headingText} blurb={blurbText} />
				<Panel className="max-w-container-xs mx-auto w-full grid gap-y-5 md:!p-11">
					<div className="mb-6">
						<div className="flex text-neutral-600 text-body-sm  items-center justify-center gap-x-2 pb-8">
							<h1>
								Login with{" "}
								<Link
									href={CARUSO_MARKETING_SITE}
									variant={LinkVariant.Primary}
									target="_blank"
									rel="noreferrer"
								>
									Caruso
								</Link>
							</h1>
						</div>
						<LoginForm
							onLoginAttemptFailed={handleLoginAttemptFailed}
							onError={handleLoginError}
							// Dismiss all toasts on successful login
							onSuccess={toast.dismiss}
						/>
					</div>
					<Link
						as={RouterLink}
						href="/forgot-password"
						className="text-label-default"
					>
						Forgot password?
					</Link>
				</Panel>
				<div className="px-6 py-7 text-center md:px-9 md:py-9">
					<span className="text-subheading-sm text-link-account">
						New to <TenantName />?{" "}
						<Link
							as={RouterLink}
							variant={LinkVariant.InvertedPrimary}
							className="text-subheading-sm"
							href="/create-account"
						>
							Create account
						</Link>
					</span>
				</div>
			</div>
		</section>
	);
};

export default Login;
